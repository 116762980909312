export const changeColorBasedOnProposalStatus = (proposalStatus) => {
  if (proposalStatus === 'Pending') {
    return '#ECC600';
  } else if (proposalStatus === 'Accepted') {
    return '#3CB503';
  } else if (proposalStatus === 'Rejected') {
    return '#F46D6D';
  } else if (proposalStatus === 'Expired') {
    return '#E1A750';
  } else if (proposalStatus === 'Canceled') {
    return '#868686';
  } else if (proposalStatus === 'Draft') {
    return '#7520FF';
  } else if (proposalStatus === 'Done') {
    return '#868686';
  }
  return 'red';
};
